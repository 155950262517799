import { defineRule, configure } from 'vee-validate'
import { required, email, numeric, max, url, mimes } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import de from '@vee-validate/i18n/dist/locale/de.json'

export default defineNuxtPlugin((app) => {
  defineRule('required', required)
  defineRule('email', email)
  // defineRule('numeric', numeric)
  // defineRule('integer', numeric)
  // defineRule('max', max)
  // defineRule('url', url)
  // defineRule('mimes', mimes)

  // defineRule('phone', (value) => {
  //   // Field is empty, should pass
  //   if (!value || !value.length) {
  //     return true
  //   }
  //   const regex = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
  //   return regex.test(value)
  // })

  // to handle the nullable fields rules from statamic
  defineRule('nullable', () => {
    return true
  })

  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: false,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: false,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: false,

    generateMessage: localize({
      de,
    }),
  })

  setLocale('de')
})

<template>
  <svg viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      y="0.000244141"
      width="24"
      height="2"
      fill="currentColor"
      class="origin-left transition-transform group-open:-translate-y-1 group-open:rotate-45"
    />
    <rect
      y="8.00024"
      width="24"
      height="2"
      fill="currentColor"
      class="opacity-100 transition-opacity group-open:opacity-0"
    />
    <rect
      y="16.0002"
      width="24"
      height="2"
      fill="currentColor"
      class="origin-left transition-transform group-open:translate-y-px group-open:-rotate-45"
    />
  </svg>
</template>

<template>
  <DynLink
    v-if="to"
    :to="to"
    v-bind="$attrs"
    :animate-previous="animatePrevious"
    :animate-next="animateNext"
    :class="[
      btnClasses,
      expand
        ? 'focus-default focus-visible:after:ring-focusTeacher after:absolute after:inset-0 focus-visible:after:ring-1 focus-visible:after:ring-offset-4'
        : '',
    ]"
    :target="
      target || (typeof to === 'string' && $isExternalUrl(to))
        ? '_blank'
        : '_self'
    "
  >
    <Component
      :is="'Icon' + (iconLeft || 'Chevron')"
      v-if="iconLeft || back"
      :class="[
        iconClasses,
        back
          ? 'transition group-hover/btn:translate-x-[-2px] group-focus-visible/btn:translate-x-[-2px]'
          : '',
      ]"
    />
    <slot />
    <Component :is="'Icon' + iconRight" v-if="iconRight" :class="iconClasses" />
  </DynLink>
  <button v-else :class="btnClasses" :type="type" v-bind="$attrs">
    <Component
      :is="'Icon' + (iconLeft || 'Chevron')"
      v-if="iconLeft || back"
      :class="[
        iconClasses,
        back
          ? 'transition group-hover/btn:translate-x-[-2px] group-focus-visible:translate-x-[-2px]'
          : '',
      ]"
    />
    <slot />
    <Component :is="'Icon' + iconRight" v-if="iconRight" :class="iconClasses" />
  </button>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

const props = withDefaults(
  defineProps<{
    to?: string | RouteLocationRaw | null
    target?: string
    iconLeft?: string
    iconRight?: string
    iconClasses?: string
    type?: 'button' | 'submit' | 'reset'
    variant?: 'regular' | 'arrow' | 'outline'
    action?: 'danger' | 'regular'
    back?: boolean
    expand?: boolean
    animateNext?: boolean
    animatePrevious?: boolean
  }>(),
  {
    iconClasses: 'w-2',
    type: 'button',
    action: 'regular',
    variant: 'regular',
    expand: false,
    animateNext: false,
    animatePrevious: false,
  }
)

// The Base style for the button
const base =
  'inline-flex gap-4 transition duration-200 whitespace-nowrap items-center group group/btn '

// The focus behavior for the button
const focus =
  'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ring-gold-500'

// The text, background and focus-ring color for the button
const variantStyles = {
  none: {
    regular: '',
    danger: '',
  },

  regular: {
    regular:
      'bg-gold-300 hover:bg-gold-400 text-gray-400 focus-visible:ring-gold-400 px-6 py-4 text-base font-semibold justify-around',
    danger: '',
  },

  arrow: {
    regular: 'text-gold-300 hover:text-gold-400 justify-start font-semibold',
    danger: '',
  },

  outline: {
    regular:
      'border-gold-300 hover:text-gold-400 hover:border-gold-400 text-gold-300 border-2 focus-visible:border-gold-400 px-6 py-4 justify-around text-base font-semibold',
    danger: '',
  },
}

const btnClasses = computed(() => {
  // Maby js map would be better here but currently there es no way to map.setDefault
  const colorKey = Object.keys(variantStyles).includes(props.variant)
    ? (props.variant as keyof typeof variantStyles)
    : 'regular'

  return [variantStyles[colorKey][props.action], base, focus]
})
</script>

<template>
  <NuxtLoadingIndicator color="#AE9960" :height="4" />

  <NuxtLayout>
    <main
      id="jump-contend-start"
      class="flex min-h-screen flex-1 flex-col overflow-hidden @container/main"
    >
      <NuxtPage />
    </main>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const formatError = (error: unknown) => {
  return createError({
    fatal: true,
    // @ts-ignore
    statusCode: error?.status ?? 500,
    message: error instanceof Error ? error.message : 'An error occurred',
  })
}

const [globals, { entry, refresh }] = await Promise.all([
  useInjectableGlobals(),
  useInjectableEntry(),
]).catch((error) => {
  throw formatError(error)
})

provide(GlobalsKey, globals)
provide(EntryInjectionKey, entry)

const router = useRouter()
router.beforeResolve(async (route) => {
  await refresh(route).catch((error) => {
    // trick vou-router into complete the navigation
    // in order to update the url and create stack entry
    setTimeout(() => showError(formatError(error)), 0)
  })
})

if (process.client) {
  document.body.classList.add(useIsTouchDevice() ? 'is-touch' : 'no-touch')
}

useHead({
  titleTemplate: (title) => {
    return title ? `${title} – Wyhlenstrasse` : 'Wyhlenstrasse'
  },
  // meta: [{ hid: 'robots', name: 'robots', content: 'noindex' }],
})
</script>

import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlueprintFlat, LazyBlueprintPage, LazyHeroLandingpage, LazyHeroRentalOffer, LazyHeroText, LazyContact, LazyGallery, LazyImageWithText, LazyMap, LazyQuote, LazyText, LazyIconArrowBack, LazyIconArrowButton, LazyIconArrowRight, LazyIconBurger, LazyIconCheckbox, LazyIconChevron, LazyIconCross, LazyIconFutura, LazyIconHouse, LazyIconLogo, LazyIconRooms, LazyIconSize, LazyIconSpin, LazyIconVogelImmobilien, LazyIconWylerstrasse, LazyUiButton, LazyUiInteractiveIllustration, LazyUiMultiselect, LazyUiSelect, LazyBard, LazyBlueprintLoader, LazyBreaky, LazyComponentsLoader, LazyContainer, LazyContentSection, LazyDynLink, LazyHeroLoader, LazyTouchTarget, LazyNavigationFooter, LazyNavigationMainDesktop, LazyNavigationMainMobile, LazyTab, LazyInputField } from '#components'
const lazyGlobalComponents = [
  ["BlueprintFlat", LazyBlueprintFlat],
["BlueprintPage", LazyBlueprintPage],
["HeroLandingpage", LazyHeroLandingpage],
["HeroRentalOffer", LazyHeroRentalOffer],
["HeroText", LazyHeroText],
["Contact", LazyContact],
["Gallery", LazyGallery],
["ImageWithText", LazyImageWithText],
["Map", LazyMap],
["Quote", LazyQuote],
["Text", LazyText],
["IconArrowBack", LazyIconArrowBack],
["IconArrowButton", LazyIconArrowButton],
["IconArrowRight", LazyIconArrowRight],
["IconBurger", LazyIconBurger],
["IconCheckbox", LazyIconCheckbox],
["IconChevron", LazyIconChevron],
["IconCross", LazyIconCross],
["IconFutura", LazyIconFutura],
["IconHouse", LazyIconHouse],
["IconLogo", LazyIconLogo],
["IconRooms", LazyIconRooms],
["IconSize", LazyIconSize],
["IconSpin", LazyIconSpin],
["IconVogelImmobilien", LazyIconVogelImmobilien],
["IconWylerstrasse", LazyIconWylerstrasse],
["UiButton", LazyUiButton],
["UiInteractiveIllustration", LazyUiInteractiveIllustration],
["UiMultiselect", LazyUiMultiselect],
["UiSelect", LazyUiSelect],
["Bard", LazyBard],
["BlueprintLoader", LazyBlueprintLoader],
["Breaky", LazyBreaky],
["ComponentsLoader", LazyComponentsLoader],
["Container", LazyContainer],
["ContentSection", LazyContentSection],
["DynLink", LazyDynLink],
["HeroLoader", LazyHeroLoader],
["TouchTarget", LazyTouchTarget],
["NavigationFooter", LazyNavigationFooter],
["NavigationMainDesktop", LazyNavigationMainDesktop],
["NavigationMainMobile", LazyNavigationMainMobile],
["Tab", LazyTab],
["InputField", LazyInputField],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

<template>
  <Container
    indent="base"
    class="group fixed left-0 top-0 z-30 w-full bg-white"
    :open="open ? 'open' : null"
    role="navigation"
    tag="nav"
  >
    <ul class="flex flex-row items-center justify-between">
      <!-- Home -->
      <li class="z-50">
        <NuxtLink to="/" aria-label="Zur Startseite" class="focus-default">
          <IconWylerstrasse
            class="focus-default my-5 w-20 !rounded-none text-gold-300 group-open:text-white xs:w-22"
          />
        </NuxtLink>
      </li>
      <li class="z-50">
        <button
          :aria-label="open ? 'Navigation Schliessen' : 'Navigation öffnen'"
          class="focus-default -mx-2 p-2 group-open:!ring-error"
          @click="open = !open"
        >
          <IconBurger class="size-6 text-gray-400" />
        </button>
      </li>
      <li
        :aria-label="open ? 'Hauptnavigation' : 'hidden'"
        class="fixed inset-0 hidden h-svh w-full bg-gold-300 px-container-mobile-sm group-open:flex xs:px-container-mobile-base"
      >
        <menu
          class="w-full flex-col items-center justify-around pb-[35svh] pt-navbar-mobile group-open:flex"
        >
          <ul class="flex w-full flex-col divide-y-1.5 divide-gold-400">
            <li
              v-for="{ page } in globals?.main.tree"
              :key="page.id"
              class="flex w-full items-center justify-between"
            >
              <NuxtLink
                class="focus-default -mx-1 my-1 flex w-full items-center justify-between px-1 py-3 text-2xl font-medium uppercase text-gray-400 underline-offset-0 transition-[text-underline-offset] hover:underline hover:underline-offset-4 focus-visible:ring-white link-active:text-white"
                :to="page.url"
                >{{ page.title }}
                <IconArrowRight class="size-4 text-gray-400" />
              </NuxtLink>
            </li>
          </ul>
        </menu>
      </li>
    </ul>
  </Container>
</template>

<script lang="ts" setup>
const globals = inject(GlobalsKey)

const open = ref(false)

// watch route and close on switch
watch(
  () => useRoute().path,
  () => {
    open.value = false
  }
)
</script>

import revive_payload_client_BfkmiWM4V9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NCUuMulhHj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xJ8NX8xqfp from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_LM28fErpVl from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_veNirMtbGj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_refGaRqjQ1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_2c87SnL56E from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.13.2_vue@3.4.29/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_ZAhx4RtsOn from "/vercel/path0/node_modules/.pnpm/nuxt-graphql-client@0.2.34_@types+node@18.19.36_graphql-tag@2.12.6_rollup@4.13.2_typescript@5.4.5/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_Cdsk3zJ2bX from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.13.2_vue@3.4.29/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_bpeFW5xcPP from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.13.2_vue@3.4.29/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_LqZXL9pGaa from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@types+node@18.19.36_@unocss+reset@0.58.8_eslint@8.57.0_floating-vue@5.2.2_rollup_uk2hze2xpsby7iuiggf4rl6mta/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import inlineUtils_X58FG1MpoS from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/inlineUtils.ts";
import livePreview_RrjgcDm40s from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/livePreview.ts";
import onError_lHlIdZiLD7 from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/onError.ts";
import vue_cloudinary_image_GGoxRgqUcV from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/vue-cloudinary-image.ts";
import inlineUtils_mS4ZLogVwO from "/vercel/path0/plugins/inlineUtils.ts";
import live_preview_rAaFLcR04D from "/vercel/path0/plugins/live-preview.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import veeValidate_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
export default [
  revive_payload_client_BfkmiWM4V9,
  unhead_NCUuMulhHj,
  router_xJ8NX8xqfp,
  payload_client_LM28fErpVl,
  check_outdated_build_client_veNirMtbGj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_refGaRqjQ1,
  motion_2c87SnL56E,
  plugin_ZAhx4RtsOn,
  composition_Cdsk3zJ2bX,
  i18n_bpeFW5xcPP,
  chunk_reload_client_LqZXL9pGaa,
  inlineUtils_X58FG1MpoS,
  livePreview_RrjgcDm40s,
  onError_lHlIdZiLD7,
  vue_cloudinary_image_GGoxRgqUcV,
  inlineUtils_mS4ZLogVwO,
  live_preview_rAaFLcR04D,
  sentry_client_GoGQuZo4Et,
  veeValidate_U1l0qzVbBC
]
<template>
  <Container
    v-motion="animation"
    indent="base"
    :class="!showNav || !thresholdCrossed ? 'top-0' : '-top-full'"
    tag="nav"
    class="fixed left-0 z-30 w-full bg-white transition-all duration-200"
    role="navigation"
  >
    <ul class="flex flex-row items-center justify-between">
      <!-- Home -->
      <li>
        <NuxtLink
          to="/"
          aria-label="Zur Startseite"
          class="focus-default -mx-2 my-2 flex items-center justify-center px-2"
        >
          <IconWylerstrasse
            class="my-5 w-18 text-gold-300 hover:text-gold-400 md:w-24 lg:w-32 xl:w-40"
          />
        </NuxtLink>
      </li>
      <li>
        <menu class="flex w-full justify-between">
          <ul class="flex gap-1">
            <li
              v-for="{ page } in globals?.main.tree"
              :key="page.id"
              class="block last-of-type:-mr-3"
            >
              <NuxtLink
                class="focus-default p-3 text-base font-semibold uppercase text-gray-400 underline-offset-0 transition-[text-underline-offset] hover:underline hover:underline-offset-6 link-active:text-gold-300 link-active:underline link-active:underline-offset-6 link-active:hover:underline-offset-10"
                :to="page.url"
                >{{ page.title }}</NuxtLink
              >
            </li>
          </ul>
        </menu>
      </li>
    </ul>
  </Container>
</template>

<script lang="ts" setup>
const globals = inject(GlobalsKey)
const { y } = useWindowScroll()

const thresholdCrossed = computed(() => unref(y) > 150)

const lastScrollTop = ref(0)
const showNav = ref(false)

watch(y, () => {
  showNav.value = y.value > lastScrollTop.value
  lastScrollTop.value = unref(y)
})

const animation = useAnimation(0, 'opacity')
</script>

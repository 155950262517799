export default defineNuxtRouteMiddleware(async (to, from) => {
  // skip middleware on server
  if (import.meta.server) return

  if (event?.type === 'popstate') return

  // is there a better way to do this?
  to.meta.pageTransition = {
    name:
      // @ts-ignore
      event?.currentTarget?.getAttribute('data-pageTransition') ||
      'default-page',
    mode: 'out-in',
  }

  from.meta.pageTransition = {
    name:
      // @ts-ignore
      event?.currentTarget?.getAttribute('data-pageTransition') ||
      'default-page',
    mode: 'out-in',
  }
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

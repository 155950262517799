export const useAnimation = (
  index = 0,
  type?:
    | 'default'
    | 'enter'
    | 'fast'
    | 'nostager'
    | 'slow'
    | 'opacity'
    | 'visible-once'
    | 'motion-reduce',
  delay = 0
) => {
  if (type === 'nostager') {
    index = 0
  }

  const preferredMotion = usePreferredReducedMotion()

  if (preferredMotion.value === 'reduce') {
    return {
      initial: {
        y: 0,
        opacity: 1,
      },
      enter: {
        y: 0,
        opacity: 1,
      },
    }
  }

  const stagerDelay = computed(() => {
    if (index !== 0 && delay !== 0) {
      return 50 * index + delay
    }
    if (index !== 0) {
      return 50 * index
    }
    if (delay !== 0) {
      return delay
    }
    return 0
  })

  const fallback = {
    initial: {
      y: 100,
      opacity: 0,
    },
    visibleOnce: {
      y: 0,
      opacity: 1,
      transition: {
        delay: stagerDelay,
        duration: 800,
        type: 'keyframes',
        ease: [0, 0, 0.16, 0.97],
      },
    },
  }

  const enter = {
    initial: {
      y: 100,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        delay: stagerDelay,
        duration: 800,
        type: 'keyframes',
        ease: [0, 0, 0.16, 0.97],
      },
    },
  }

  const visibleOnce = {
    initial: {
      y: 100,
      opacity: 0,
    },
    visibleOnce: {
      y: 0,
      opacity: 1,
      transition: {
        delay: stagerDelay,
        duration: 800,
        type: 'keyframes',
        ease: [0, 0, 0.16, 0.97],
      },
    },
  }

  const opacity = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        delay: stagerDelay,
        duration: 500,
      },
    },
  }

  const fast = {
    initial: {
      y: 100,
      opacity: 0,
    },
    visibleOnce: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 250,
        type: 'keyframes',
        ease: [0, 0, 0.16, 0.97],
      },
    },
  }

  const slow = {
    initial: {
      y: 100,
      opacity: 0,
    },
    visibleOnce: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1200,
        type: 'keyframes',
        ease: [0, 0, 0.16, 0.97],
      },
    },
  }

  if (type === 'fast') {
    return fast
  }
  if (type === 'visible-once') {
    return visibleOnce
  }
  if (type === 'opacity') {
    return opacity
  }
  if (type === 'slow') {
    return slow
  }
  if (type === 'enter') {
    return enter
  }
  if (type === 'default') {
    return fallback
  }

  return fallback
}

<template>
  <svg viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.79189 7.99475C9.06937 8.27382 9.06937 8.7262 8.79189 9.00527L1.54787 16.2907C1.27039 16.5698 0.820582 16.5698 0.543099 16.2907L0.208112 15.9538C-0.0693707 15.6748 -0.0693707 15.2223 0.208112 14.9433L6.61475 8.50001L0.208112 2.05673C-0.0693705 1.77766 -0.0693705 1.32528 0.208112 1.04621L0.5431 0.709302C0.820582 0.430231 1.27039 0.430231 1.54787 0.709302L8.79189 7.99475Z"
      fill="currentColor"
    />
  </svg>
</template>

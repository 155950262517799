<template>
  <NuxtLink
    :to="extentedTo"
    target="_self"
    :data-pageTransition="genTransitionName()"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
const router = useRouter()

const props = withDefaults(
  defineProps<{
    to?: string | RouteLocationRaw | null
    animateNext?: boolean
    animatePrevious?: boolean
  }>(),
  {
    animateNext: false,
    animatePrevious: false,
  }
)

const extentedTo = computed(() => {
  if (!props.to || isExternalUrl(String(props.to))) return props.to

  const location = router.resolve(props.to)
  const params = location.params || {}

  return {
    name: location.name,
    params,
    query: location.query,
    hash: location.hash,
  }
})

function genTransitionName() {
  if (props.animateNext) {
    return 'next-page'
  }
  if (props.animatePrevious) {
    return 'previous-page'
  }

  return 'default-page'
}
</script>

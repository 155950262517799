<template>
  <div class="flex min-h-screen w-full flex-col justify-between">
    <NuxtErrorBoundary>
      <NavigationMainDesktop id="jump-navigation" class="max-sm:hidden" />
      <NavigationMainMobile id="jump-navigation" class="sm:hidden" />
    </NuxtErrorBoundary>

    <NuxtErrorBoundary>
      <main class="flex flex-1 flex-col justify-center">
        <h1
          class="text-teacher-heading-xl mx-auto mt-32 text-center"
          v-html="
            error?.statusCode === 404
              ? `${$t('error.notFound')}`
              : `${$t('error.error')}`
          "
        />
        <div class="mx-auto my-8 text-center text-3xl opacity-50">
          {{ error?.statusCode }}
        </div>
        <UiButton to="/" class="mx-auto mb-[20vh]" type="primary">
          {{ $t('error.back') }}
        </UiButton>

        
      </main>
    </NuxtErrorBoundary>

    <NuxtErrorBoundary>
      <!-- <TeacherFooter /> -->
    </NuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
if (process.client) {
  document.body.classList.add(useIsTouchDevice() ? 'is-touch' : 'no-touch')
}

const props = defineProps<{
  error: any
}>()

try {
  provide(GlobalsKey, await useInjectableGlobals())
} catch (error) {
  console.error('[ERROR Page]: Could not load globals', error)
}

try {
  const { refresh } = await useInjectableEntry()
  const router = useRouter()
  router.beforeResolve(refresh)
} catch (error) {
  console.error('[ERROR Page]: Could not load entry', error)
}
</script>
